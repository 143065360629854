export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35')
];

export const server_loads = [0];

export const dictionary = {
		"/": [2],
		"/(app)/about": [14],
		"/(profile)/account": [30],
		"/(profile)/billing": [31],
		"/(app)/(labs)/case-studies": [3],
		"/(app)/(labs)/challenge": [~4],
		"/(partnership)/client": [27],
		"/(app)/(labs)/collections": [~5],
		"/(support)/contact": [33],
		"/(others)/cookies-policy": [21],
		"/(others)/creator": [22],
		"/(others)/creator/resume": [23],
		"/(app)/(labs)/design": [6],
		"/(app)/(labs)/design/art": [7],
		"/(app)/(labs)/design/mobile": [8],
		"/(app)/(labs)/design/website": [9],
		"/(app)/freebies": [15],
		"/(app)/(labs)/labs": [10],
		"/(links)/links": [20],
		"/(support)/live-chat": [34],
		"/(blog)/note": [19],
		"/offline": [35],
		"/(partnership)/partner": [28],
		"/(partnership)/partner/register": [29],
		"/(profile)/plans": [32],
		"/(others)/privacy-policy": [24],
		"/(app)/(services)/service": [12],
		"/(auth)/sign-in": [16],
		"/(auth)/sign-out": [17],
		"/(auth)/sign-up": [18],
		"/(others)/sitemap": [25],
		"/(app)/(labs)/technology": [11],
		"/(others)/terms-condition": [26],
		"/(app)/(works)/works": [~13]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';